import project1_cover from "src/images/project/1/cover.jpg"
import project1_1 from "src/images/project/1/1.png"
import project1_2 from "src/images/project/1/2.png"
import project1_3 from "src/images/project/1/3.png"
import project2_cover from "src/images/project/2/cover.png"
import project2_1 from "src/images/project/2/1.png"
import project2_2 from "src/images/project/2/2.png"
import project2_3 from "src/images/project/2/3.png"
import project3_cover from "src/images/project/3/cover.png"
import project3_1 from "src/images/project/3/1.png"
import project3_2 from "src/images/project/3/2.png"
import project4_cover from "src/images/project/4/cover.png"
import project4_1 from "src/images/project/4/1.jpg"
import project4_2 from "src/images/project/4/2.jpg"
import project4_3 from "src/images/project/4/3.jpg"
import project4_4 from "src/images/project/4/4.jpg"
import project5_cover from "src/images/project/5/cover.png"
import project5_1 from "src/images/project/5/1.jpg"
import project5_2 from "src/images/project/5/2.jpg"
import project5_3 from "src/images/project/5/3.jpg"
import project6_cover from "src/images/project/6/cover.jpg"
import project6_1 from "src/images/project/6/1.jpg"
import project6_2 from "src/images/project/6/2.jpg"
import project6_3 from "src/images/project/6/3.jpg"
import project6_4 from "src/images/project/6/4.jpg"
import project7_cover from "src/images/project/7/cover.png"
import project7_1 from "src/images/project/7/1.jpg"
import project7_2 from "src/images/project/7/2.jpg"
import project7_3 from "src/images/project/7/3.jpg"
import project8_cover from "src/images/project/8/cover.png"
import project8_1 from "src/images/project/8/1.jpg"
import project8_2 from "src/images/project/8/2.jpg"
import project8_3 from "src/images/project/8/3.jpg"
import project9_cover from "src/images/project/9/cover.jpg"
import project9_1 from "src/images/project/9/1.jpg"
import project10_cover from "src/images/project/10/cover.jpg"
import project11_cover from "src/images/project/11/cover.jpg"
import project11_1 from "src/images/project/11/1.png"
import project11_2 from "src/images/project/11/2.jpg"
import project12_cover from "src/images/project/12/cover.jpg"
import project12_1 from "src/images/project/12/1.jpg"
import project12_2 from "src/images/project/12/2.jpg"
import project13_cover from "src/images/project/13/cover.webp"
import project13_1 from "src/images/project/13/1.webp"

type Project = {
  projectId: number
  imsrc: any[] // cover would always be the first image
  nameId: string
  workId: string
  clientId: string
  architectId: string
  periodId: string
  feature?: boolean
  //maximum featured project could only be 3
}

//data is stored in intl/en.json and intl/zh.json
export const projects: Project[] = [
  {
    projectId: 1,
    imsrc: [project1_cover, project1_1, project1_2, project1_3],
    nameId: "project-01-name",
    workId: "project-01-work",
    clientId: "project-01-client",
    architectId: "project-01-architect",
    periodId: "project-01-period",
    feature: true,
  },
  {
    projectId: 2,
    imsrc: [project2_cover, project2_1, project2_2, project2_3],
    nameId: "project-02-name",
    workId: "project-02-work",
    clientId: "project-02-client",
    architectId: "project-02-architect",
    periodId: "project-02-period",
    feature: true,
  },
  {
    projectId: 3,
    imsrc: [project3_cover, project3_1, project3_2],
    nameId: "project-03-name",
    workId: "project-03-work",
    clientId: "project-03-client",
    architectId: "project-03-architect",
    periodId: "project-03-period",
    feature: true,
  },
  {
    projectId: 4,
    imsrc: [project4_cover, project4_1, project4_2, project4_3, project4_4],
    nameId: "project-04-name",
    workId: "project-04-work",
    clientId: "project-04-client",
    architectId: "project-04-architect",
    periodId: "project-04-period",
  },
  {
    projectId: 5,
    imsrc: [project5_cover, project5_1, project5_2, project5_3],
    nameId: "project-05-name",
    workId: "project-05-work",
    clientId: "project-05-client",
    architectId: "project-05-architect",
    periodId: "project-05-period",
  },
  {
    projectId: 6,
    imsrc: [project6_cover, project6_1, project6_2, project6_3, project6_4],
    nameId: "project-06-name",
    workId: "project-06-work",
    clientId: "project-06-client",
    architectId: "project-06-architect",
    periodId: "project-06-period",
  },
  {
    projectId: 7,
    imsrc: [project7_cover, project7_1, project7_2, project7_3],
    nameId: "project-07-name",
    workId: "project-07-work",
    clientId: "project-07-client",
    architectId: "project-07-architect",
    periodId: "project-07-period",
  },
  {
    projectId: 8,
    imsrc: [project8_cover, project8_1, project8_2, project8_3],
    nameId: "project-08-name",
    workId: "project-08-work",
    clientId: "project-08-client",
    architectId: "project-08-architect",
    periodId: "project-08-period",
  },
  {
    projectId: 9,
    imsrc: [project9_cover, project9_1],
    nameId: "project-09-name",
    workId: "project-09-work",
    clientId: "project-09-client",
    architectId: "project-09-architect",
    periodId: "project-09-period",
  },
  {
    projectId: 10,
    imsrc: [project10_cover],
    nameId: "project-10-name",
    workId: "project-10-work",
    clientId: "project-10-client",
    architectId: "project-10-architect",
    periodId: "project-10-period",
  },
  {
    projectId: 11,
    imsrc: [project11_cover, project11_1, project11_2],
    nameId: "project-11-name",
    workId: "project-11-work",
    clientId: "project-11-client",
    architectId: "project-11-architect",
    periodId: "project-11-period",
  },
  {
    projectId: 12,
    imsrc: [project12_cover, project12_1, project12_2],
    nameId: "project-12-name",
    workId: "project-12-work",
    clientId: "project-12-client",
    architectId: "project-12-architect",
    periodId: "project-12-period",
  },
  {
    projectId: 13,
    imsrc: [project13_cover, project13_1],
    nameId: "project-13-name",
    workId: "project-13-work",
    clientId: "project-13-client",
    architectId: "project-13-architect",
    periodId: "project-13-period",
  },
]
