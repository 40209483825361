import React from 'react'
// @ts-ignore
import * as Styles from './YearBox.module.scss'
const YearBox = ({ period }: { period: string }) => {
    return (
        <div className={Styles.yearBox}>
            {period}
        </div>
    )
}

export default YearBox
